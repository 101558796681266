import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import VueGtag from "vue-gtag";
// src/main.js
import './assets/global.css';

createApp(App)
  .use(VueGtag, {config: { id: "G-XTQ0WCE9QN" }})
  .use(router)
  .mount('#app');
