<template>
  <div>
    <h1>User Devices</h1>

    <button @click="showAddDeviceModal = true" class="add-device-btn">
      ➕ Add Device
    </button>
    <button @click="refreshDevices" class="add-device-btn">
      🔄 Refresh Devices
    </button>

    <confirm-modal
      :show="showAddDeviceModal"
      title="Enter Device Name"
      :isInput="true"
      @confirm="addDevice"
      @cancel="onAddDeviceModalCancel"
    ></confirm-modal>

    <div class="devices-container">
      <div
        class="device-card"
        v-for="(device, index) in devices.slice().reverse()"
        :key="device.id"
      >
        <!-- Header with editable name -->
        <div class="device-header">
          <div class="device-name" @click="editDeviceName">
            <input
              v-model="device.name"
              @blur="saveDeviceName"
              class="editable-input"
              :readonly="!isEditing"
            />
          </div>
          <span style="padding-right: 20px">
            {{ device.ip ? "🟢" : "🔴" }}
          </span>
          <button
            class="delete-device-btn"
            @click.stop="confirmDeviceDeletion(index)"
          >
            X
          </button>
        </div>

        <div class="device-info">
          <span
            ><strong>Payment Status: </strong> {{ device.paymentStatus }}</span
          >
          <span>&nbsp;</span>
          <span
            ><strong>IP: </strong
            ><a
              v-if="device.ip"
              :href="'https://whois.domaintools.com/' + device.ip"
              target="_blank"
              >{{ device.ip }}</a
            ><span v-else>-</span>
            <span>&nbsp;{{ formatDate(device.date) }}</span>
          </span>

          <!-- Device ID -->
          <span @click="toggleVisibility(index, 'deviceID')" class="revealable">
            <strong>Device ID: </strong>
            <span v-if="visibleItems[index] && visibleItems[index].deviceID">
              {{ device.deviceID }}
              <button
                @click.stop="copyToClipboard(device.deviceID)"
                class="copy-button"
              >
                📋
              </button>
            </span>
            <button v-else class="lock-button">🔒</button>
          </span>
          <!-- Secret Key -->
          <span @click="toggleVisibility(index, 'key')" class="revealable">
            <strong>Secret Key: </strong>
            <span v-if="visibleItems[index] && visibleItems[index].key">
              {{ device.key }}
              <button
                @click.stop="copyToClipboard(device.key)"
                class="copy-button"
              >
                📋
              </button>
            </span>
            <button v-else class="lock-button">🔒</button>
          </span>

          <!-- asterisk URL -->
          <span
            @click="toggleVisibility(index, 'asteriskURL')"
            class="revealable"
          >
            <strong>Asterisk URL: </strong>
            <span v-if="visibleItems[index] && visibleItems[index].asteriskURL">
              {{ device.asterisk }}
              <button
                @click.stop="copyToClipboard(device.asterisk)"
                class="copy-button"
              >
                📋
              </button>
            </span>
            <button v-else class="lock-button">🔒</button>
          </span>
        </div>
        <div class="device-actions">
          <span>
            <button v-if="device.ip" @click="openTerminal(device)">
              🖥 Terminal
            </button>
            <button v-if="device.ip" @click="viewDesktopDirect(device)">
              💻 Desktop (Direct)
            </button>
            <!-- <button v-if="device.ip" @click="viewDesktop(device)">
              💻 Desktop (Cloudflare)
            </button> -->
          </span>
        </div>
        <div class="device-actions">
          <span>
            <button @click="openTelegramModal(device)">
              📩 Set Telegram API
            </button>
            <button @click="openGetWebhookInfoModal(device)">
              ℹ️ Get Telegram Webhook Info
            </button>
          </span>
          <button @click="showDownloadModal = true">⬇️ Download Client</button>
        </div>
      </div>
    </div>
    <confirm-modal
      ref="telegramModal"
      :show="showTelegramModal"
      title="Set Telegram Webhook"
      placeholder="Enter Bot Token"
      :isInput="true"
      :iconSrc="'/assets/telegram_logo.svg'"
      :defaultValue="defaultBotToken"
      @confirm="setTelegramAPI"
      @cancel="onTelegramModalCancel"
    ></confirm-modal>

    <confirm-modal
      :show="showGetWebhookInfoModal"
      title="Get Webhook Info"
      placeholder="Enter Bot Token"
      :isInput="true"
      :defaultValue="defaultBotToken"
      @confirm="getTelegramWebhookInfo"
      @cancel="onGetWebhookInfoModalCancel"
    ></confirm-modal>

    <confirm-modal
      :show="showDownloadModal"
      title="Download and Install Client"
      :message="`Click the button below to <a href='https://piflare.com/assets/${package_name}' target='_blank'>download the client</a>.<br>
              After downloading, open the terminal and run the following commands to install it:<br><br>
              <code>cd Downloads</code><br>
              <code>sudo apt-get install ./${package_name}</code>`"
      @confirm="onDownloadModalConfirm"
      :confirmText="`Download ${package_name}`"
      @cancel="onDownloadModalCancel"
    ></confirm-modal>

    <confirm-modal
      :show="showDeleteDeviceModal"
      title="Delete Device?"
      @confirm="deleteDevice"
      @cancel="onDeleteDeviceModalCancel"
    ></confirm-modal>
  </div>
</template>

<script>
import axios from "axios";
import ConfirmModal from "../components/ConfirmModal.vue";
// Importing required modules
import { Terminal } from "xterm";
import "xterm/css/xterm.css"; // Import xterm.css for styling

export default {
  components: {
    ConfirmModal,
  },
  data() {
    return {
      package_name: "piflare_1.0_all.deb",
      devices: [],
      showAddDeviceModal: false,
      showTelegramModal: false,
      showGetWebhookInfoModal: false,
      visibleItems: {},
      currentDeviceId: null,
      defaultBotToken: "",
      showDownloadModal: false,
      showDeleteDeviceModal: false,
      isEditing: false,
    };
  },
  methods: {
    editDeviceName(index) {
      this.isEditing = true;
      console.log("editing device " + index);
    },
    saveDeviceName() {
      this.isEditing = false;
      // Save the device name or perform any other necessary actions
      console.log("edited device ");
    },
    confirmDeviceDeletion(index) {
      this.showDeleteDeviceModal = true;
      console.log("Delete " + index);
      // store the index or any information necessary for deletion
    },
    deleteDevice() {
      // logic for deleting the device
      this.showDeleteDeviceModal = false;
    },
    onDeleteDeviceModalCancel() {
      this.showDeleteDeviceModal = false;
    },
    onDownloadModalCancel() {
      this.showDownloadModal = false;
    },
    onDownloadModalConfirm() {
      const packageurl = `https://piflare.com/assets/${this.package_name}`;
      window.open(packageurl, "_blank");
    },
    viewDesktopDirect(device) {
      const url = `/assets/noVNC/vncdirect.html?device=${device.deviceID}&autoconnect=1`;
      window.open(url, "_blank");
    },
    openTerminal(device) {
      const terminalContainer = document.createElement("div");
      terminalContainer.style.height = "600px";
      terminalContainer.style.width = "800px";
      terminalContainer.style.backgroundColor = "#000";
      terminalContainer.style.position = "fixed";
      terminalContainer.style.top = "50%";
      terminalContainer.style.left = "50%";
      terminalContainer.style.transform = "translate(-50%, -50%)";
      terminalContainer.style.border = "1px solid #ccc";
      terminalContainer.style.borderRadius = "4px";
      terminalContainer.style.resize = "both";
      terminalContainer.style.overflow = "auto";
      document.body.appendChild(terminalContainer);

      // Add a close button
      const closeButton = document.createElement("button");
      closeButton.innerHTML = "Close";
      closeButton.style.position = "absolute";
      closeButton.style.right = "10px";
      closeButton.style.top = "10px";
      closeButton.style.zIndex = "10";
      closeButton.onclick = () => {
        document.body.removeChild(terminalContainer);
      };
      terminalContainer.appendChild(closeButton);

      const terminal = new Terminal({
        fontFamily: "monospace",
        fontSize: 14,
        cursorBlink: true,
        theme: {
          background: "#000",
          foreground: "#FFF",
          cursor: "#FFF",
          cursorAccent: "#000",
          selection: "rgba(255, 255, 255, 0.3)",
        },
      });

      terminal.open(terminalContainer);
      terminal.focus();
      terminal.resize(90, 36); // Set the number of columns and rows for the terminal

      // Add custom CSS styles to align text to the left
      terminalContainer.style.textAlign = "left";
      terminalContainer.style.justifyContent = "flex-start";

      const socketUrl = `wss://api.piflare.com/ws/${device.deviceID}/terminal`;
      const socket = new WebSocket(socketUrl);

      socket.onopen = () => {
        terminal.writeln("Connected to SSH server...");
        socket.send("");
      };

      terminal.onData((data) => {
        socket.send(data);
      });

      socket.onmessage = (event) => {
        terminal.write(event.data);
      };

      socket.onclose = () => {
        terminal.writeln("SSH connection closed");
      };

      socket.onerror = (error) => {
        terminal.writeln(`SSH connection error: ${error}`);
      };
    },
    formatDate(timestamp) {
      if (!timestamp) return null;

      try {
        const now = Date.now();
        const diffInMilliseconds = now - timestamp;
        const diffInMinutes = Math.floor(diffInMilliseconds / (60 * 1000));
        const diffInHours = Math.floor(diffInMilliseconds / (60 * 60 * 1000));

        if (diffInHours < 1) {
          // If the difference is less than 1 hour, show in minutes
          return "(" + `${diffInMinutes} minutes ago` + ")";
        } else if (diffInHours === 1) {
          // If the difference is equal to 1 hour, show in minutes
          return "(" + `${diffInHours} hour ago` + ")";
        } else if (diffInHours < 72) {
          // If the difference is less than 24 hours, show in hours
          return "(" + `${diffInHours} hours ago` + ")";
        } else {
          // Otherwise, show the full date
          let date = new Date(timestamp);
          return "(" + date.toLocaleString() + ")";
        }
      } catch (e) {
        return null;
      }
    },
    openGetWebhookInfoModal(device) {
      this.currentDeviceId = device.deviceID;
      this.defaultBotToken =
        localStorage.getItem(`botToken-${this.currentDeviceId}`) || "";
      this.showGetWebhookInfoModal = true;
    },
    openTelegramModal(device) {
      this.currentDeviceId = device.deviceID;
      this.defaultBotToken =
        localStorage.getItem(`botToken-${this.currentDeviceId}`) || "";
      this.showTelegramModal = true;
    },
    setTelegramAPI(botToken) {
      this.showTelegramModal = false;
      localStorage.setItem(`botToken-${this.currentDeviceId}`, botToken);
      const botUrl = `https://api.telegram.org/bot${botToken}/setWebhook?url=https://api.piflare.com/web/${this.currentDeviceId}/webhook/`;
      window.open(botUrl, "_blank");
    },
    getTelegramWebhookInfo(botToken) {
      this.showGetWebhookInfoModal = false;
      localStorage.setItem(`botToken-${this.currentDeviceId}`, botToken);
      const botUrl = `https://api.telegram.org/bot${botToken}/getWebhookInfo`;
      window.open(botUrl, "_blank");
    },
    onGetWebhookInfoModalCancel() {
      this.showGetWebhookInfoModal = false;
    },
    onTelegramModalCancel() {
      this.showTelegramModal = false;
    },
    toggleVisibility(index, field) {
      // Toggle the visibility
      if (!this.visibleItems[index]) {
        this.visibleItems[index] = {};
      }
      this.visibleItems[index][field] = !this.visibleItems[index][field];
    },
    copyToClipboard(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          console.log("Copied to clipboard");
          // you can change the button label to "Copied!" here or display some visual feedback
        })
        .catch((err) => {
          console.error("Could not copy text: ", err);
        });
    },
    async status() {
      try {
        const response = await axios
          .create({ withCredentials: true })
          .post(`https://panel.piflare.com/api/service`, { action: "status" });
        this.devices = response.data; // assuming response.data is an array of devices
        console.log("User status:", response);
      } catch (error) {
        console.error("Status error:", error);
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: "UserLogin" }).then(() => window.location.reload());
        }
      }
    },
    renameDevice(device) {
      const newName = prompt("Enter new name for the device:", device.name);
      if (newName) {
        console.log(`Renaming device ${device.id} to ${newName}`);
        // Here you could send the newName and device.id to your server
        // via an HTTP request, for example.
      }
    },
    downloadConfig(device) {
      // Download config logic here
      console.log(`Deleting device ${device.id}`);
    },
    async addDevice(deviceName) {
      try {
        this.showAddDeviceModal = false;
        // Step 1: Send request to create a new device
        await axios
          .create({ withCredentials: true })
          .post(`https://panel.piflare.com/api/service`, {
            action: "create",
            devicename: deviceName,
          });

        // Create an overlay
        const overlay = document.createElement("div");
        overlay.className = "overlay";
        document.body.appendChild(overlay);

        // Step 2: Show a loading box with a progress bar
        let percentage = 0;
        const loadingBox = document.createElement("div");
        loadingBox.className = "loading-box";
        document.body.appendChild(loadingBox);

        const progressText = document.createElement("div");
        progressText.className = "progress-text";
        progressText.innerHTML =
          "Creating a new device, it may take upto 1minute...";
        loadingBox.appendChild(progressText);

        const progressBarContainer = document.createElement("div");
        progressBarContainer.className = "progress-bar-container";
        loadingBox.appendChild(progressBarContainer);

        const progressBar = document.createElement("div");
        progressBar.className = "progress-bar";
        progressBarContainer.appendChild(progressBar);

        const intervalId = setInterval(async () => {
          // Step 3: Send request to check the status
          const response = await axios
            .create({ withCredentials: true })
            .post(`https://panel.piflare.com/api/service`, {
              action: "status",
            });
          if (response.data.length > this.devices.length) {
            // Step 4: New device added, close loading box and update devices list
            clearInterval(intervalId);
            clearInterval(percentageInterval);
            document.body.removeChild(loadingBox);
            document.body.removeChild(overlay);
            this.devices = response.data;
          }

          // Increase the percentage
          percentage += (10 / 60) * 100;
          if (percentage >= 100) {
            clearInterval(intervalId);
            document.body.removeChild(loadingBox);
            document.body.removeChild(overlay);
            alert("Adding new device timed out");
          }
        }, 10000); // 10 seconds

        // Update the progress bar
        const percentageInterval = setInterval(() => {
          // Increment percentage in smaller steps
          percentage += 1; // Increment by 1% every second

          // Update the progress bar width
          progressBar.style.width = `${Math.min(percentage, 100)}%`;

          // If 100% is reached, clear the interval
          if (percentage >= 100) {
            clearInterval(percentageInterval);
          }
        }, 1000); // Update every second
      } catch (error) {
        console.error("Error adding device:", error);
      }
    },
    onAddDeviceModalCancel() {
      this.showAddDeviceModal = false;
    },
    refreshDevices() {
      this.devices = [];
      this.status();
    },
  },
  mounted() {
    this.status(); // call status() method on page load
  },
};
</script>

<style>
/* Existing styles */

.devices-container {
  display: flex;
  flex-direction: column;
}

.device-card {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 20px;
  margin: 10px;
  flex-basis: calc(33.333% - 20px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.device-name {
  flex-grow: 1;
  text-align: center;
}

.device-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px; /* Padding around the header */
  background-color: #f0f0f0; /* Light background color for the header */
}

body.dark-mode .device-header {
  background-color: #444; /* Dark background color for the header in dark mode */
}

body.dark-mode .device-card {
  background-color: #333;
  border-color: #444;
  color: #fff; /* text color */
}

body.dark-mode .delete-device-btn {
  color: #f00;
}

.delete-device-btn {
  color: red;
}

.editable-input {
  box-sizing: content-box;
  text-align: center;
  background: transparent;
  border: none;
  font-size: 1.2em;
  padding: 1px 2px;
  height: initial;
  font-weight: bold;
  color: rgb(49, 49, 49);
  width: auto; /* You can adjust the width or let it be automatically sized */
  cursor: text;
}

.editable-input:hover {
  background: rgb(255, 255, 255);
  border: 2px solid #ccc;
}

body.dark-mode .editable-input {
  color: #fff;
}

body.dark-mode .editable-input:hover {
  background: #444;
  border-color: #888;
}

.device-info {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding-top: 15px;
}

.device-actions {
  margin-top: 20px;
}

.device-actions button {
  margin: 1px;
  padding: 8px;
}

.add-device-btn {
  margin: 20px;
  padding: 10px;
  cursor: pointer;
}

/* Dark mode styles */

body.dark-mode .device-card {
  background-color: #333;
  border: 1px solid #444;
  color: #f1f1f1;
}

body.dark-mode .add-device-btn {
  background-color: #444;
  color: #f1f1f1;
  border: 1px solid #555;
}

body.dark-mode .device-info {
  color: #f1f1f1;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
}

.loading-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  padding: 20px;
  text-align: center;
  border-radius: 4px;
  z-index: 1001;
}

.progress-bar-container {
  width: 100%;
  background-color: #f1f1f1;
  height: 20px;
  border-radius: 3px;
}

.progress-text {
  margin-bottom: 10px;
}

.progress-bar {
  background-color: #4caf50;
  width: 0;
  height: 100%;
  border-radius: 3px;
}

/* Dark mode styles */
body.dark-mode .loading-box {
  background-color: #333;
  color: #fff;
  border: 1px solid #444;
}

body.dark-mode .progress-bar {
  background-color: #76b852;
}

body.dark-mode .overlay {
  background-color: rgba(0, 0, 0, 0.7);
}

.revealable {
  cursor: pointer;
}

.lock-button,
.copy-button {
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1.5em;
  padding: 0;
  margin: 0 5px;
}

.lock-button:hover,
.copy-button:hover {
  color: #007bff;
}
</style>
