<template>
  <div class="center">
    <h2>Register</h2>

    <!-- Display error message -->
    <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
    <p v-if="resendMessage" class="success">{{ resendMessage }}</p>

    <!-- Step 1: Registration Form -->
    <form v-if="step === 1" @submit.prevent="register" class="form-inline-block">
      <div>
        <input type="email" v-model="email" placeholder="Email" required class="input-field" />
      </div>
      <div>
        <input type="password" v-model="password" placeholder="Password" required class="input-field" />
      </div>
      <button type="submit" :disabled="isLoading" class="submit-button">Register</button>
    </form>

    <!-- Step 2: Verification Form -->
    <form v-if="step === 2" @submit.prevent="activateAccount" class="form-inline-block">
      <div>
        <input type="text" v-model="activationCode" placeholder="6-digit code" required class="input-field" />
      </div>
      <p>Check your email for the activation code (also check spam).</p>
      <button type="submit" :disabled="isLoading" class="submit-button">Verify</button>
      <button @click="resendVerification" :disabled="isLoading" class="resend-button">Resend Verification Email</button>
    </form>

    <!-- Step 3: Account Activated -->
    <div v-if="step === 3">
      <p>Account successfully registered and activated!</p>
      <button @click="redirectToStatus" class="continue-button">Continue to Devices</button>
    </div>

  </div>
</template>

<script>
/* global grecaptcha */
import axios from 'axios';

export default {
  data() {
    return {
      email: '',
      password: '',
      activationCode: '',
      errorMessage: null,
      siteKey: '6Ld1wK0mAAAAANjCrP8QSlV0RZNVT95x753EQBUh',
      step: 1,
      resendMessage: null,
      isLoading: false
    };
  },
  mounted() {
    let recaptchaScript = document.createElement('script');
    recaptchaScript.setAttribute('src', `https://www.google.com/recaptcha/api.js?render=${this.siteKey}`);
    recaptchaScript.setAttribute('async', '');
    recaptchaScript.setAttribute('defer', '');
    document.head.appendChild(recaptchaScript);
    // Initialize from route parameters (if available)
    // Initialize from route query parameters (if available)
    if (this.$route.query.step) {
      // Convert step to number
      this.step = Number(this.$route.query.step);
    }
    if (this.$route.query.email) {
      this.email = this.$route.query.email;
    }
  },
  watch: {
    // Watch for changes to the route
    $route(to) {
      if (to.query.step) {
        this.step = to.query.step;
      }
      if (to.query.email) {
        this.email = to.query.email;
      }
    },
  },
  methods: {
    async register() {
      this.isLoading = true;
      this.errorMessage = null;
      try {
        const recaptchaResponse = await grecaptcha.execute(this.siteKey, { action: 'register' });
        await axios.create({ withCredentials: true }).post('https://panel.piflare.com/api/register', {
          email: this.email,
          password: this.password,
          recaptchaResponse,
        });
        this.step = 2;
      } catch (error) {
        this.errorMessage = 'Register error: ' + error.response.data;
      } finally {
        this.isLoading = false;
      }
    },
    async activateAccount() {
      this.isLoading = true;
      this.errorMessage = null;
      try {
        const response = await axios.create({ withCredentials: true }).post('https://panel.piflare.com/api/activate', {
          email: this.email,
          activationCode: this.activationCode,
        });

        // Check if the response status is 200 (OK)
        if (response.status === 200) {
          this.step = 3;
          this.resendMessage = null;

          // Emit custom event to indicate that the user is logged in
          this.$emit('userLoggedIn');
        } else {
          // Handle non-200 response status if needed
          this.errorMessage = 'Activation succeeded, but response status is not 200';
        }
      } catch (error) {
        // Handle activation errors
        this.errorMessage = 'Verification error: ' + error.response.data;
      } finally {
        this.isLoading = false;
      }
    },
    async resendVerification() {
      this.isLoading = true;
      this.errorMessage = null;
      try {
        await axios.create({ withCredentials: true }).post('https://panel.piflare.com/api/resendcode', {
          email: this.email,
        });
        // Set the resendMessage to indicate success
        this.resendMessage = 'Verification email has been resent successfully! Please also check the SPAM/Junk section of your email account.';
      } catch (error) {
        this.errorMessage = 'Resend verification error: ' + error.response.data;
      } finally {
        this.isLoading = false;
      }
    },
    redirectToStatus() {
      this.$router.push({ name: 'UserStatus' });
    },
  },
};
</script>
