<template>
  <div v-if="show" class="modal-overlay">
    <div class="modal">
      <h2>{{ title }}</h2>
      <p v-if="message" v-html="message" style="text-align:left"></p>
      <div v-if="iconSrc"><img :src="iconSrc" alt="Icon" class="icon-in-modal" /></div>
      <input v-if="isInput" v-model="inputValue" type="text" :placeholder="placeholder" class="input-field-modal">
      <div class="modal-actions">
        <button class="confirm-btn" @click="onConfirm">{{confirmText || "Confirm"}}</button>
        <button class="cancel-btn" @click="onCancel">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['show', 'title','confirmText', 'message', 'isInput', 'iconSrc', 'defaultValue', 'placeholder'],
  data() {
    return {
      inputValue: this.defaultValue || '' 
    };
  },
  methods: {
    onConfirm() {
      if (this.isInput) {
        this.$emit('confirm', this.inputValue);
      } else {
        this.$emit('confirm');
      }
    },
    onCancel() {
      this.$emit('cancel');
    },
  },
  watch: {
    // Reset input value when the defaultValue changes
    defaultValue(newVal) {
      this.inputValue = newVal || '';
    }
  }
};
</script>

<style>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Segoe UI', 'Roboto', sans-serif;
}

.modal {
  background: #f9f9f9;
  padding: 20px; /* Reduce the padding of the modal */
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  width: 80%;
  max-width: 500px;
  text-align: center;
}

.input-field-modal {
  width: 80%;
  padding: 10px 5px; /* Reduce the horizontal padding */
  margin: 15px 0px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modal h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.modal p {
  font-size: 16px;
  margin-bottom: 30px;
}

.modal-actions {
  display: flex;
  justify-content: space-around;
}

.confirm-btn, .cancel-btn {
  padding: 10px 20px;
  margin:20px;
  font-size: 16px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.confirm-btn {
  background-color: #007BFF;
  color: white;
}

.cancel-btn {
  background-color: #6C757D;
  color: white;
}

.confirm-btn:hover {
  background-color: #0056b3;
}

.cancel-btn:hover {
  background-color: #5a6268;
}

/* Dark mode styles for the modal */
body.dark-mode .modal {
    background: #2a2a2a;
    color: #f1f1f1;
}

body.dark-mode .confirm-btn {
    background-color: #0056b3;
}

body.dark-mode .cancel-btn {
    background-color: #5a6268;
}

body.dark-mode .confirm-btn:hover {
    background-color: #003c80;
}

body.dark-mode .cancel-btn:hover {
    background-color: #484c50;
}

.icon-in-modal {
  width: 40px;
  height: 40px;
  margin: 10px 0;
}

</style>
