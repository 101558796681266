<template>
  <div class="center">
    <h2>Login</h2>
    <!-- Display error message -->
    <p v-if="errorMessage" class="error">{{ errorMessage }}</p>

    <form @submit.prevent="login" class="form-inline-block">
      <div>
        <input type="email" v-model="email" placeholder="Email" required class="input-field" />
      </div>
      <div>
        <input type="password" v-model="password" placeholder="Password" required class="input-field" />
      </div>
      <button type="submit" class="submit-button">Login</button>
    </form>
  </div>
</template>
  
  <script>
  /* global grecaptcha */
  import axios from 'axios';
  
  export default {
    data() {
      return {
        email: '',
        password: '',
        errorMessage: null,
        siteKey: '6Ld1wK0mAAAAANjCrP8QSlV0RZNVT95x753EQBUh',
      };
    },
    mounted() {
      let recaptchaScript = document.createElement('script');
      recaptchaScript.setAttribute('src', `https://www.google.com/recaptcha/api.js?render=${this.siteKey}`);
      recaptchaScript.setAttribute('async', '');
      recaptchaScript.setAttribute('defer', '');
      document.head.appendChild(recaptchaScript);
    },
    methods: {
      // Login component
      async login() {
        try {
          const recaptchaResponse = await grecaptcha.execute(this.siteKey, { action: 'login' });
          const response = await axios.create({ withCredentials: true }).post(`https://panel.piflare.com/api/login`, {
            email: this.email,
            password: this.password,
            recaptchaResponse,
            withCredentials: true,
          });
          console.log('User logged in:', response);

          // Emit custom event after successful login
          this.$emit('userLoggedIn');

          // Redirect to the status page
          this.$router.push({ name: 'UserStatus' });
        } catch (error) {
          console.error('Login error:', error);
          // Check if the error status is 403 and message is "Account is not activated"
          if (error.response.status === 403 && error.response.data === "Account is not activated") {
            // Redirect to the registration page with step 2 (activation)
            this.$router.push({ name: 'UserRegister', query: { step: 2, email: this.email } });
          } else {
            // Set the error message
            this.errorMessage = 'Login failed: ' + error.response.data;
          }
        }
      },
    },
  };
  </script>
  