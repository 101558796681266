<template>
  <div id="app">
    <div id="header">
      <div class="mode-toggle-container">
        <button
          class="mode-toggle"
          @click="toggleDarkMode"
          :class="darkModeClass"
          :aria-label="darkModeAriaLabel"
        >
          <div class="toggle">
            <div id="dark-mode-indicator"></div>
          </div>
        </button>
      </div>
      <h1>PiFlare - IoT Remote Management</h1>
      <h3>Connect and Control Your IoT Devices with Ease Remotely</h3>
    </div>
    <div id="nav">
      <router-link to="/">Home</router-link>
      <span> | </span>

      <!-- Show Login and Register links when user is NOT logged in -->
      <template v-if="!isLoggedIn">
        <router-link to="/login">Login</router-link>
        <span> | </span>
        <router-link to="/register">Register</router-link>
      </template>

      <!-- Show Status and Logout links when user IS logged in -->
      <template v-if="isLoggedIn">
        <router-link to="/status">Status</router-link>
        <span> | </span>
        <a href="#" @click="promptLogout">Logout</a>
      </template>
    </div>
    <router-view @userLoggedIn="handleUserLoggedIn" />

    <!-- Contact Section -->
    <div id="contact">
      <h2>Contact Us</h2>
      <p>
        Reach out to us at
        <a href="mailto:info@piflare.com">info@piflare.com</a>
      </p>
    </div>

    <!-- Footer Section -->
    <div id="footer">
      <p>© 2023 PiFlare - All Rights Reserved.</p>
    </div>
  </div>
  <ConfirmModal
    :show="showConfirmModal"
    title="Logout"
    message="Are you sure you want to logout?"
    @confirm="handleLogoutConfirmation"
    @cancel="handleLogoutCancellation"
  />
</template>

<script>
import ConfirmModal from "./components/ConfirmModal.vue";
import axios from "axios";

export default {
  name: "App",
  components: {
    ConfirmModal,
  },
  data() {
    return {
      isLoggedIn: false,
      isDarkModeEnabled: localStorage.getItem("darkMode")
        ? localStorage.getItem("darkMode") === "true"
        : window.matchMedia &&
          window.matchMedia("(prefers-color-scheme: dark)").matches,
      showConfirmModal: false,
    };
  },
  created() {
    document.title = "PiFlare - IoT Remote Management";
    // Check login status
    axios
      .post(
        "https://panel.piflare.com/api/status",
        {},
        { withCredentials: true }
      )
      .then((response) => {
        // If the status is 200, the session is valid
        if (response.status === 200) {
          this.isLoggedIn = true;
        } else {
          this.isLoggedIn = false;
        }
      })
      .catch((error) => {
        // If an error occurs or the status is 401, set isLoggedIn to false
        console.error(error);
        this.isLoggedIn = false;
      });

    // Apply the dark mode class if needed on creation
    if (this.isDarkModeEnabled) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
  },
  methods: {
    // Modify the promptLogout method to toggle the custom modal
    promptLogout() {
      this.showConfirmModal = true;
    },
    // Handle the logout confirmation
    async handleLogoutConfirmation() {
      this.showConfirmModal = false;
      // Implementing logout logic here
      try {
        const response = await axios
          .create({ withCredentials: true })
          .post(
            `https://panel.piflare.com/api/logout`,
            {},
            { withCredentials: true }
          );
        console.log("User logged out:", response);
        // Update isLoggedIn data property
        this.isLoggedIn = false;
        // Redirect to the Home page or any other page after logout
        this.$router.push({ name: "HomePage" });
      } catch (error) {
        console.error("Logout error:", error);
      }
    },
    // Handle the logout cancellation
    handleLogoutCancellation() {
      this.showConfirmModal = false;
    },
    handleUserLoggedIn() {
      this.isLoggedIn = true;
    },
    enableDarkMode() {
      document.body.classList.add("dark-mode");
      this.isDarkModeEnabled = true;
      localStorage.setItem("darkMode", "true");
    },

    disableDarkMode() {
      document.body.classList.remove("dark-mode");
      this.isDarkModeEnabled = false;
      localStorage.setItem("darkMode", "false");
    },

    toggleDarkMode() {
      if (this.isDarkModeEnabled) {
        this.disableDarkMode();
      } else {
        this.enableDarkMode();
      }
    },
  },
  computed: {
    darkModeClass() {
      return this.isDarkModeEnabled ? "darkmode-toggled" : "";
    },
    darkModeAriaLabel() {
      return this.isDarkModeEnabled ? "Disable dark mode" : "Enable dark mode";
    },
  },
};
</script>
