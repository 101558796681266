<template>
    <div class="home-container">
      <!-- <section class="hero-section">
        <div class="hero-image">
          // Place a hero image showing a Raspberry Pi connected to a mobile device 
        </div>
      </section> -->
  
      <section class="features-section">
        <h2>Features</h2>
        <div class="features-grid">
          <div class="feature-card">
            <div class="feature-icon">
              <!-- Place an icon for API connectivity -->
            </div>
            <h3>Remote Management</h3>
            <p>Gain Remote Access to Your IoT Device: Terminal and Desktop Control</p>
          </div>
          <div class="feature-card">
            <div class="feature-icon">
              <!-- Place an icon for Telegram BOT -->
            </div>
            <h3>Telegram Bot</h3>
            <p>Create Custom Telegram Bots for Your Raspberry Pi and Control It Using Your Mobile Phone</p>
          </div>
          <div class="feature-card">
            <div class="feature-icon">
              <!-- Place an icon for security -->
            </div>
            <h3>No Intermediate Servers</h3>
            <p>Connect With Cloudflare's Edge Computing For a Direct, Secure, and Low-latency Connection</p>
          </div>
        </div>
      </section>
  
      <section class="get-started-section">
        <h2>Get Started Today!</h2>
        <p>Take the first step to elevate your Raspberry Pi experience.</p>
        <button class="get-started-button">
          <router-link to="/register">Sign Up Now</router-link>
        </button>
      </section>
  
    </div>
  </template>
  
  <script>
  export default {
    name: 'HomePage',
  };
  </script>

<style scoped>
  .home-container {
    text-align: center;
  }
  
  .hero-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
    background-image: linear-gradient(to right, rgb(118, 196, 226), rgb(133, 203, 168));
    color: #2c3e50;
  }
  
  .features-section {
    padding: 5px 50px 50px 50px ;
  }
  
  .features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
  }
  
  .feature-card {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .get-started-section {
    background-image: linear-gradient(to right, rgb(118, 196, 226), rgb(133, 203, 168));
    color: #2c3e50;
    padding: 50px;
  }
  
  .get-started-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: white;
    color: #4CAF50;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  

  
</style>
